const host = 'https://cyglapi.yuedu.press';
// const host = 'http://172.20.10.2:17895';

import router from './routes';
import axios from 'axios';
export default {
    install(app) {
        app.config.globalProperties.$http = {
            post(path = '', data = {}, headers = {}) {
                return new Promise((resolve, reject) => {
                    let url = host + '/api/' + path.split('.').filter(element => element).join('/');
                    let token = app.config.globalProperties.$store.get('_token');
                    console.log('发送携带的token', token)
                    axios({
                        url,
                        method: 'POST',
                        data: data,
                        headers: {
                            authorization: token,
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                            ...headers
                        },
                        // withCredentials: true, //自动携带cookies
                    }).then(res => {
                        console.log(path, 'post返回', res)
                        let {
                            data,
                            errMsg,
                            errCode
                        } = res.data;
                        if (!errCode) {
                            console.log('是的呢')
                            resolve(data)
                        } else {
                            switch (errCode) {
                                case 2: //登录过期
                                    app.config.globalProperties.$router.push('/login')
                                    setTimeout(() => {
                                        app.config.globalProperties.$message.info(errMsg)
                                    }, 200);
                                    app.config.globalProperties.$store.set('token', null)
                                    // reject(res.data)
                                    break
                                default:
                                    app.config.globalProperties.$message.info(errMsg)
                                    reject(res.data)
                            }
                        }
                    }).catch(err => {
                        console.log('网络错误', err)
                    })
                })
            },
            get(path = '', data = {}, headers = {}) {
                return new Promise((resolve, reject) => {
                    let url = host + '/api/' + path;
                    let token = this.$store.get('token');
                    axios({
                        url,
                        method: 'GET',
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                            ...headers
                        },
                        data: data,
                    }).then(res => {
                        let {
                            data,
                            errMsg,
                            errCode
                        } = res.data;
                        console.log(!errCode)
                        if (!errCode) {
                            console.log('是的呢')
                            resolve(data)
                        } else {
                            switch (errCode) {
                                case 2: //登录过期
                                    // uni.showToast({
                                    //     title: errMsg,
                                    //     icon: 'none'
                                    // })
                                    // uni.setStorageSync(token, null)
                                    break
                                default:
                                    // uni.showToast({
                                    //     title: errMsg,
                                    //     icon: 'none'
                                    // })
                                    reject(res.data)
                            }
                        }

                    }).catch(err => {
                        console.log('网络错误', err)
                    })
                })
            }

        };
    }
}