<template>
  <div style="flex-direction: column;user-select: none;">
    <div style="width: 100vw;;display: flex;align-items: center;margin-bottom: 8px;justify-content: space-between;">
      <div style="display: flex;align-items: center;">
        <div class="btn" @click="showAddPersionModal"
          style="padding: 5px 17px;font-size: 14px;--btn-backgroundColor: #1488f5;--btn-hover-backgroundColor: #1488f5ee;margin-top: 10px;">
          成员管理</div>
        <div class="btn" @click="showAddGroup" style="padding: 5px 17px;font-size: 14px;margin-top: 10px;">
          分组管理</div>
        <div class="btn" @click="showSelectColor"
          style="padding: 5px 17px;font-size: 14px;margin-top: 10px;--btn-backgroundColor: #fff;--btn-hover-backgroundColor: #ffffffaa;color: #000;display: flex;align-items: center;">
          颜色
          <div style="width: 13px;height: 13px;border-radius: 50%;margin-left: 5px;margin-top: 2px;"
            :style="{ backgroundColor: colorSelect }"></div>
        </div>
        <div class="btn" @click="tianchong"
          style="padding: 5px 10px;font-size: 14px;margin-top: 10px;--btn-backgroundColor: #fff;--btn-hover-backgroundColor: #ffffffaa;color: #000;display: flex;align-items: center;"
          :style="{ backgroundColor: tianse ? '#b0b0b0' : '#fff' }">
          <svg t="1689691950357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2852" width="17" height="17">
            <path
              d="M963 703.2v-48.1c0-23.5-19.1-42.7-42.7-42.7h-83c-23.5 0-42.7 19.1-42.7 42.7v48.1c0 2.5 0.9 5 2.5 6.9l18.7 23.1-19.2 27c-1.3 1.9-2 4.1-2 6.4v117.7c0 23.5 19.1 42.7 42.7 42.7h83c23.5 0 42.7-19.1 42.7-42.7V766.5c0-2.3-0.7-4.5-2-6.4l-19.2-27 18.7-23.1c1.6-1.8 2.5-4.3 2.5-6.8z m-146.3-48.1c0-11.4 9.3-20.7 20.7-20.7h83c11.4 0 20.7 9.3 20.7 20.7v44.2l-19.3 23.8H836l-19.3-23.8v-44.2zM854 485.9c0-12.2-4.7-23.7-13.3-32.3l-303.1-303c-8.6-8.6-20-13.3-32.3-13.3-12.2 0-23.7 4.7-32.3 13.3l-72.7 72.7-113.7-113.9c-8.6-8.6-22.5-8.6-31.1 0-8.6 8.6-8.6 22.5 0 31.1l113.9 113.9L80 543.7c-8.6 8.6-13.3 20-13.3 32.3 0 12.2 4.7 23.7 13.3 32.3l303.1 303.1c8.6 8.6 20 13.3 32.3 13.3 12.2 0 23.7-4.7 32.3-13.3l393.1-393.1c8.5-8.7 13.2-20.2 13.2-32.4zM523.6 346.4l-92-92 72.7-72.7c0.3-0.3 0.7-0.4 1.1-0.4 0.5 0 0.8 0.1 1.1 0.4l303.1 303.1c0.3 0.3 0.4 0.7 0.4 1.1 0 0.5-0.1 0.8-0.4 1.1l-0.3 0.3H198.6l201.9-201.9 92 92c4.3 4.3 9.9 6.4 15.6 6.4 5.6 0 11.3-2.1 15.6-6.4 8.5-8.5 8.5-22.4-0.1-31z"
              fill="#4D4D4D" p-id="2853"></path>
          </svg>
        </div>
      </div>
      <img @click="logout" src="../assets/exit.png"
        style="width: 20px;height: 20px;border-radius: 50%;background-color: #eeeeee;cursor: pointer;margin-right: 15px;padding-top: 5px;"
        alt="">
    </div>
    <div class="itxst">
      <div class="group" v-for="(item, index) in state.modules" :key="index">
        <div style="padding-bottom: 10px;padding-left: 5px;text-align: center;font-size: 18px;user-select: none;">
          {{ item.groupName }} ({{ item.list.length }})<i class="file_menu_icon del_file_icon "></i></div>
        <draggable style="min-height: 50vh;" :list="item.list" ghost-class="ghost" handle=".move" filter=".forbid"
          :force-fallback="true" chosen-class="chosenClass" animation="300" @start="onStart" @end="onEnd"
          :group="state.groupA" :fallback-class="true" :fallback-on-body="true" :touch-start-threshold="50"
          :fallback-tolerance="50" :move="onMove" :sort="false">
          <template #item="{ element }">
            <div class="item move"
              style="width: 100%;display: flex;align-items: center;justify-content: center;color: white;">
              <label @click="changeMemberColor(element)" :class="tianse ? 'tianse' : 'move'"
                style="width: 100%;display: flex;align-items: center;justify-content: center;user-select: none;color: white;"
                :style="{ backgroundColor: element.bgcolor || '#fff', color: element.bgcolor == '#ffffff' || !element.bgcolor ? '#000' : '#fff' }">{{
                  element.name
                }}</label>
              <!-- <span v-html="element.name == '消息' ? 'www.itxst.com' : '内容....'"></span> -->
            </div>
          </template>
        </draggable>
      </div>

    </div>
    <Modal ref="add">
      <template v-slot:body="">
        <div>
          <div style="display: flex;align-items: center;justify-content: start;">
            <a-input :onPressEnter="addPersion" v-model:value="member_name" addonBefore="姓名:"
              style="width: 250px;"></a-input>
            <div class="btn" @click="addPersion"
              style="font-size: 14px;--btn-backgroundColor: #1488f5;--btn-hover-backgroundColor: #1488f5ee;">
              添加成员</div>
          </div>
          <a-table :dataSource="dataSource" :columns="columns" size="middle" style="margin-top: 3px;">
            <template #operation="{ record }">
              <div @click="deletePersion(record)"
                style="cursor: pointer;display: flex;align-items: center;justify-content: center;">
                <img src="../assets//delete.png" style="width: 16px;height: 16px;" alt="">
              </div>
            </template>
          </a-table>
        </div>
      </template>
    </Modal>

    <Modal ref="tjfz">
      <template v-slot:body="">
        <div>
          <div style="display: flex;align-items: center;justify-content: start;">
            <a-input :onPressEnter="addGroup" v-model:value="group_name" addonBefore="姓名:"
              style="width: 250px;"></a-input>
            <div class="btn" @click="addGroup"
              style="font-size: 14px;--btn-backgroundColor: #1488f5;--btn-hover-backgroundColor: #1488f5ee;">
              添加分组</div>
          </div>
          <a-table :dataSource="dataSource2" :columns="columns2" size="middle" style="margin-top: 3px;">
            <template #operation="{ record, index }">
              <div v-if="index !== 0" style="display: flex;align-items: center;">
                <div @click="deleteGroup(record)"
                  style="cursor: pointer;display: flex;align-items: center;justify-content: center;margin-right: 18px;">
                  <img src="../assets/delete.png" style="width: 16px;height: 16px;" alt="">
                </div>

                <div v-if="index !== 1" @click="upmoveGroup(record)"
                  style="cursor: pointer;display: flex;align-items: center;justify-content: center;">
                  <img src="../assets/up.png" style="width: 17px;height: 17px;" alt="">
                </div>
              </div>

            </template>
          </a-table>
        </div>
      </template>
    </Modal>

    <Modal ref="xzys">
      <template v-slot:body="">
        <div style="display: flex;">
          <div v-for="(item, index) in colorList" :key="index" class="ysxz" @click="colorChange(item)"
            style="width: 25%;display: flex;align-items: center;justify-content: center;height: 130px;cursor: pointer;border-radius:5px;">
            <div style="width: 60px;height: 60px;border-radius: 50%;box-shadow:inset 0 0 30px 0 #00000012;"
              :style="{ backgroundColor: item }"></div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- <div>{{ state.modules }}</div> -->
  </div>
</template>
<script setup>
import Modal from '../components/Modal/Modal';
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
//导入draggable组件
import draggable from "vuedraggable";
const instance = getCurrentInstance();
const that = instance.appContext.config.globalProperties;

const state = reactive({
  message: "A组只能往B组拖到一个元素",
  groupA: {
    name: "itxst",
    put: true, //允许拖入
    pull: () => {
      // let res = state.modules.arr1.length > 3;
      // //当A组元素小于等于3个时，不允许再拖出元素了
      // state.message = res ? "只能拖出1个" : "不能再拖了";
      // return res;
    },
  },
  modules: {
    // arr1: [
    //   { name: "张三", id: 1 },
    //   { name: "李四", id: 2 },
    //   { name: "王五", id: 3 },
    //   { name: "日志", id: 4 },
    // ],
    // arr2: [
    //   { name: "B组", id: 5 },
    //   { name: "员工", id: 6 },
    //   { name: "报表", id: 7 },
    // ],
  },
});


const colorList = ref([
  '#ffffff', '#1488f5',
  '#ed4014', '#107c41', '#c38e2e'
])
onMounted(() => {
  getGroup()
})

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 100,
    slots: { customRender: 'operation' },
  },
]
const dataSource = ref([])


const columns2 = [
  {
    title: '分组名称',
    dataIndex: 'groupName',
    key: 'groupName',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 80,
    slots: { customRender: 'operation' },
  },
]
const dataSource2 = ref([])
const colorSelect = ref('#ffffff')
const tianse = ref(false)

const tianchong = () => {
  console.log('开始填色', tianse.value)
  tianse.value = tianse.value ? false : true
  console.log('开始填色', tianse.value)
}
const showSelectColor = () => {
  instance.refs.xzys.show({
    title: '选择颜色',
    showFooter: false,
    form: [
    ]
  }, (data) => {

  })
}

const colorChange = (color) => {
  colorSelect.value = color
  console.log('选择颜色', color)
  instance.refs.xzys.hide()
}

const changeMemberColor = (record) => {
  if (tianse.value) {
    let t_color = record.bgcolor;
    record.bgcolor = colorSelect.value
    console.log('点击填充', colorSelect)
    that.$http.post('admin.changeMemberColor', {
      ...record, bgcolor: colorSelect.value
    }).then(res => {
      getGroup()
    }).catch(err => {
      record.bgcolor = t_color
    })
  }
}


const getGroup = () => {
  that.$http.post('admin.getGroup', {}).then(res => {
    console.log('获取的数据', res.group)
    state.modules = res.group;
    dataSource.value = res.members;
    dataSource2.value = res.group;
  })
}

const showAddPersionModal = () => {
  instance.refs.add.show({
    title: '成员管理',
    showFooter: false,
    form: [
    ]
  }, (data) => {

  })
}

const showAddGroup = () => {
  instance.refs.tjfz.show({
    title: '成员管理',
    showFooter: false,
    form: [
    ]
  }, (data) => {

  })
}
const member_name = ref('')
const addPersion = () => {
  if (member_name.value && member_name.value.length > 0) {
    console.log('名称输入', member_name)
    that.$http.post('admin.addPersion', {
      name: member_name.value
    }).then(res => {
      that.$message.success('操作成功！')
      getGroup()
      // getAccountList()
    })
  }
}
const deletePersion = (record) => {
  that.$http.post('admin.deletePersion', {
    ...record
  }).then(res => {
    that.$message.success('操作成功！')
    getGroup()
    // getAccountList()
  })
}


const group_name = ref('')
const addGroup = () => {
  console.log('撒打算')
  if (group_name.value && group_name.value.length > 0) {
    console.log('名称输入', group_name)
    that.$http.post('admin.addGroup', {
      groupName: group_name.value
    }).then(res => {
      that.$message.success('操作成功！')
      getGroup()
      // getAccountList()
    })
  }
}


const deleteGroup = (record) => {
  that.$http.post('admin.deleteGroup', {
    ...record
  }).then(res => {
    that.$message.success('操作成功！')
    getGroup()
    // getAccountList()
  })
}
const upmoveGroup = (record) => {
  that.$http.post('admin.upmoveGroup', {
    ...record
  }).then(res => {
    getGroup()
    // getAccountList()
  })
}
//拖拽开始的事件
const onTouchStart = () => {
  console.log("开始拖拽");
  // document.body.style.overflow = 'hidden'
};

//拖拽结束的事件
const onEnd = (e) => {
  console.log("结束拖拽", e.oldIndex, e.newIndex, state.modules);
  that.$http.post('admin.updateGroup', {
    group: state.modules
  }).then(res => {
    getGroup()
    // getAccountList()
  })
};
const logout = () => {
  that.$store.set('_token', null)
  that.$router.push('/login')
}
const onMove = (e, originalEvent) => {
  //不允许停靠
  // if (e.relatedContext.element.disabledPark == true) return false;

  // return true;
};
</script>
<style scoped>
.btn {
  --btn-backgroundColor: #19be6b;
  --btn-hover-backgroundColor: #47cb89;
}

.btn {
  background-color: var(--btn-backgroundColor);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  /* margin-left: 14px; */
  box-sizing: border-box;
}

.btn:hover {
  background-color: var(--btn-hover-backgroundColor);
}

body {
  padding: 0px;
  margin: 0px;
  background-color: #f1f1f1;
}

.msg {
  padding: 10px 20px 0px 20px;
}

.itxst {
  background-color: #f1f1f1;
  /* display: flex; */
  padding: 20px 10px;
}

.group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  min-width: 100px;
  margin-right: 20px;
  float: left;
}

.item {
  border: solid 1px #ddd;
  padding: 0px;
  text-align: left;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  /* height: 36px; */
  user-select: none;
}

.item>label {
  padding: 6px 10px;
  color: #333;
}

.item:hover {
  cursor: move;
}

.tianse {
  cursor: pointer;
}

.move {
  cursor: move;
}

.item>span {
  padding: 6px 10px;
  color: #666;
}

.ghost {
  border: solid 1px rgb(19, 41, 239) !important;
}

.chosenClass {
  opacity: 1;
  border: solid 1px red;
}

.fallbackClass {
  background-color: aquamarine;
}



.ysxz {
  background-color: white;
}

.ysxz:hover {
  background-color: #f0f0f0;
}
</style>