<template>
  <div style="display: flex;" :class="[$route.name === 'login' ? 'login-page' : '']">
    <!-- <div v-if="$route.name !== 'login'&&$route.name !== 'pldfExcl'" class="sidebar" style="min-width: 240px;z-index: 88;height: 100vh;">
      <left style="position: fixed;left: 0;top: 0;min-width: 240px;z-index: 555;" v-on:menuSelect="menuSelect">
      </left>
    </div>
   -->

    <div class="main-content" style="flex-grow: 1;">
      <!-- 路由视图 -->
      <a-config-provider :locale="locale">
        <router-view style="box-sizing: border-box;"></router-view>
      </a-config-provider>
    </div>
  </div>
</template>

<script>
import left from './components/left.vue'
import index from './content/index.vue'
import Fitness from './content/Fitness.vue'
import account from './content/account.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN';//引入antd中文包
import moment from 'moment';//引入moment 
moment.locale('zh_CN');//配置moment中文環境

export default {
  name: 'App',
  components: {
    left, index, Fitness, account
  },
  data() {
    return {
      componentId: 'index',
      locale: zhCN,
      phone: ''
    }
  },
  methods: {
    menuSelect(e) {
      console.log(e.key)
      // let key = e.key
      // this.componentId = e.key

    },
    logout() {
      this.$store.set('_token', null)
      this.$router.push('/login')
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
  /* zoom: 100%;  */
}

.main-content {
  flex-grow: 1;
  /* padding-top: 60px; */
  /* 距离顶部导航栏的距离 */
  /* 其他样式 */
}

.login-page .main-content {
  padding-top: 0;
}
</style>
